export const ATTACHMENT_TYPE = {
  pdf: "PDF",
  links: "Link",
  diagram: "Image",
};

export const SEARCH_TAB_TITLE = {
  title: "In the title",
  content: "In the content",
  publisher: "By Publisher",
  video: "Stephanie suggestions",
};

export const EDIT_TYPE = {
  trim: "Trim",
  erase: "Erase",
  voiceOver: "Voice Over",
};

export const ANNOTATION_TYPE = {
  "i-text": "Text",
  path: "Draw",
  arrow: "Arrow",
  image: "Image",
};

export const INPUT_TYPE = {
  link: "URL",
  label: "Text",
  other: "Other",
};
