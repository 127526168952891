import firebase from "firebase";
import environment from "../../env";
import AliOss from "../js/AliOss";
import store from "../store";
import eventbus from "../main";
import { ADDON_TYPE, ATTACHMENT_FILE_PATH } from "../constants/attachmentStatus";

export const isAliServer = environment.backendType === "ali";

export const getFirebaseGsURL = (url) => {
  return firebase.storage().refFromURL(url).toString();
};

export const getUuidv4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const uploadToFirebase = (folder, file, metadata, onStateChange) => {
  // Get a reference to the storage service, which is used to create references in your storage bucket
  const storage = firebase.storage();
  // Create a storage reference from our storage service
  const storageRef = storage.ref();
  return new Promise(function (resolve, reject) {
    const uploadTask = storageRef.child(folder + file.name).put(file, metadata);

    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
      function (snapshot) {
        store.dispatch("docGoogleUrlBridgeReplacement", snapshot.task);
        eventbus.$emit("update-idle-timer", { dateTime: new Date() });
        onStateChange && onStateChange(snapshot, firebase);
      },
      function (error) {
        console.error(error);
        reject(error.code);
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            break;

          case "storage/canceled":
            // User canceled the upload
            break;

          case "storage/unknown":
            // Unknown error occurred, inspect error.serverResponse
            break;
        }
      },
      function () {
        // Upload completed successfully, now we can get the download URL
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          resolve(downloadURL.split("?")[0]);
        });
      }
    );
  });
};

export const uploadToAliOss = async (folder, file, metadata, onStateChange) => {
  try {
    const result = await AliOss.uploadFile(folder, file, metadata, (percentage, checkpoint, res) => {
      eventbus.$emit("update-idle-timer", { dateTime: new Date() });
      onStateChange && onStateChange(percentage, checkpoint, res);
    });
    return result.url;
  } catch (error) {
    console.error(error);
    return error.code;
  }
};

export const uploadToCloudStorageByFileType = async (fileType, file, metadata, onStateChange) => {
  const organization = store.state.userProfile[0].organization;
  const pathByFileType = {
    annotationImage: "/images/annotations/",
    poster: "/images/posters/",
    playlistPoster: "/images/playlist/posters/",
    audio: "/audios/",
    video: "/videos/",
    [ADDON_TYPE.DIAGRAM]: ATTACHMENT_FILE_PATH[ADDON_TYPE.DIAGRAM],
    [ADDON_TYPE.PDF]: ATTACHMENT_FILE_PATH[ADDON_TYPE.PDF],
  };
  const path = `${organization}${pathByFileType[fileType]}`;
  const currentUploader = isAliServer ? uploadToAliOss : uploadToFirebase;
  const downloadURL = await currentUploader(path, file, metadata, onStateChange);
  console.log(path + file.name + " available at", downloadURL);
  return downloadURL;
};
