import { ANNOTATION_COLOR, ANNOTATION_LINE_WIDTH_VALUE, ANNOTATION_TYPE } from "@/constants/annotationStatus";
import { fabric } from "fabric";

export const getTextDefaultConfig = ({
  fill = ANNOTATION_COLOR.RED,
  top = 0,
  left = 0,
  isRedraw = false,
  id = Math.random().toString(16).slice(2),
  fontWeight,
  fontStyle,
  backgroundColor = "",
  text = "text",
  scaleX = 0,
  scaleY = 0,
  endTime = 0,
  startTime = 0,
  fontFamily = "Noto Serif",
}) => ({
  left,
  top,
  fill,
  fontWeight: fontWeight || "normal",
  fontStyle: fontStyle || "normal",
  backgroundColor,
  isRedraw,
  id,
  endTime,
  startTime,
  text,
  fontFamily,
  ...(scaleX && { scaleX }),
  ...(scaleY && { scaleY }),
});

export const getArrowDefaultConfig = ({
  stroke = ANNOTATION_COLOR.RED,
  strokeWidth = ANNOTATION_LINE_WIDTH_VALUE.NORMAL,
  top = 0,
  left = 0,
  isRedraw = false,
  id = Math.random().toString(16).slice(2),
  scaleX = 0,
  scaleY = 0,
  endTime = 0,
  startTime = 0,
  startX = 50,
  startY = 50,
  endX = 200,
  endY = 100,
}) => ({
  stroke,
  strokeWidth, // it cannot be empty or null
  top,
  left,
  isRedraw,
  id,
  endTime,
  startTime,
  startX,
  startY,
  endX,
  endY,
  ...(scaleX && { scaleX }),
  ...(scaleY && { scaleY }),
});

export const getImageDefaultConfig = ({
  top = 0,
  left = 0,
  isRedraw = false,
  id = Math.random().toString(16).slice(2),
  scaleX = 0,
  scaleY = 0,
  endTime = 0,
  startTime = 0,
  width,
  height,
  imageUrl,
}) => ({
  top,
  left,
  isRedraw,
  id,
  endTime,
  startTime,
  width,
  height,
  src: imageUrl,
  ...(scaleX && { scaleX }),
  ...(scaleY && { scaleY }),
});

export class Text {
  constructor() {
    this.TextConfig = {
      top: 0,
      left: 0,
      config: {},
    };

    this.Fabric = null;
  }

  handleTextMouseDown = (e) => {
    const {
      pointer: { x: left, y: top },
    } = e;
    this.TextConfig.top = top;
    this.TextConfig.left = left;
  };

  handleTextMouseUp = () => {
    this.unbindTextMouseEvent();
    this.Fabric.add(
      new fabric.IText("text", {
        ...this.TextConfig.config,
        id: Math.random().toString(16).slice(2),
        top: this.TextConfig.top,
        left: this.TextConfig.left,
      })
    );
  };

  bindTextMouseEvent(config) {
    this.TextConfig.config = config;
    this.unbindTextMouseEvent();
    this.Fabric.on("mouse:down", this.handleTextMouseDown);
    this.Fabric.on("mouse:up", this.handleTextMouseUp);
  }

  unbindTextMouseEvent() {
    this.Fabric.off("mouse:down", this.handleTextMouseDown);
    this.Fabric.off("mouse:up", this.handleTextMouseUp);
  }
}

export class Arrow extends Text {
  constructor() {
    super();
    this.ArrowConfig = {
      tempArrow: null,
      isDrawing: false,
      mousePoints: {
        start: { x: 0, y: 0 },
        end: { x: 0, y: 0 },
        top: 0,
        left: 0,
      },
      config: {},
      arrowLimitLength: 20,
    };

    this.Fabric = null;
  }

  drawLine() {
    if (this.ArrowConfig.tempArrow != null) {
      this.Fabric.remove(this.ArrowConfig.tempArrow);
    }
    this.ArrowConfig.tempArrow = new fabric.Arrow(
      [
        this.ArrowConfig.mousePoints.start.x,
        this.ArrowConfig.mousePoints.start.y,
        this.ArrowConfig.mousePoints.end.x,
        this.ArrowConfig.mousePoints.end.y,
      ],
      {
        stroke: "#ff0600",
        strokeWidth: 6,
        isDrawing: this.ArrowConfig.isDrawing,
      }
    );
    const { top, left } = this.ArrowConfig.tempArrow;
    this.ArrowConfig.mousePoints.top = top;
    this.ArrowConfig.mousePoints.left = left;
    this.Fabric.add(this.ArrowConfig.tempArrow);
  }

  _checkPointBoundaries(pointer) {
    if (pointer.y < 0 || pointer.x < 0) {
      pointer.y = Math.max(pointer.y, 0);
      pointer.x = Math.max(pointer.x, 0);
    }

    const LEFT_MAXIMUM = this.canvasWidth - 5;
    const TOP_MAXIMUM = this.canvasHeight - 5;
    if (pointer.y >= TOP_MAXIMUM || pointer.x >= LEFT_MAXIMUM) {
      pointer.y = Math.min(TOP_MAXIMUM, pointer.y);
      pointer.x = Math.min(LEFT_MAXIMUM, pointer.x);
    }
    return pointer;
  }

  handleMouseDown = (e) => {
    const { pointer } = e;
    this.ArrowConfig.isDrawing = true;
    this.ArrowConfig.mousePoints.start = this._checkPointBoundaries(pointer);
  };

  handleMouseMove = (e) => {
    if (!this.ArrowConfig.isDrawing) return;
    const { pointer } = e;
    this.ArrowConfig.mousePoints.end = this._checkPointBoundaries(pointer);
    this.drawLine();
  };

  handleMouseUp = async (e) => {
    await Promise.resolve(this.Fabric.remove(this.ArrowConfig.tempArrow));
    const { pointer } = e;
    this.ArrowConfig.mousePoints.end = this._checkPointBoundaries(pointer);
    const {
      start: { x: startX, y: startY },
      end: { x: endX, y: endY },
      top,
      left,
    } = this.ArrowConfig.mousePoints;
    this.ArrowConfig.isDrawing = false;
    this.ArrowConfig.tempArrow = null;
    this.toggleAnnotationEvent(true);
    if (this.calculateLength(this.ArrowConfig.mousePoints) > this.ArrowConfig.arrowLimitLength) {
      this.Fabric.add(
        new fabric.Arrow([startX, startY, endX, endY], {
          ...this.ArrowConfig.config,
          top,
          left,
          startX,
          startY,
          endX,
          endY,
          id: Math.random().toString(16).slice(2),
        })
      );
      this.unbindArrowMouseEvent();
    } else {
      console.error(`arrow's length is shorter than ${this.ArrowConfig.arrowLimitLength}, please try again`);
      this.unbindArrowMouseEvent();
      this.bindArrowMouseEvent(this.ArrowConfig.config);
    }
  };

  calculateLength(point) {
    const {
      start: { x: startX, y: startY },
      end: { x: endX, y: endY },
    } = point;
    const x = endX - startX;
    const y = endY - startY;
    return Math.sqrt(x * x + y * y);
  }

  bindArrowMouseEvent(config) {
    this.ArrowConfig.config = config;
    this.unbindArrowMouseEvent();
    this.Fabric.on("mouse:down", this.handleMouseDown);
    this.Fabric.on("mouse:move", this.handleMouseMove);
    this.Fabric.on("mouse:up", this.handleMouseUp);
  }

  unbindArrowMouseEvent() {
    this.Fabric.off("mouse:down", this.handleMouseDown);
    this.Fabric.off("mouse:move", this.handleMouseMove);
    this.Fabric.off("mouse:up", this.handleMouseUp);
  }

  toggleAnnotationEvent() {
    // this function will be overridden
    console.log("if you see this message, that mean your function is not overridden");
  }
}

export class AnnotationUtils extends Arrow {
  unbindMouseEvent(type) {
    switch (type) {
      case ANNOTATION_TYPE.TEXT:
        this.unbindTextMouseEvent();
        break;
      case ANNOTATION_TYPE.ARROW:
        this.unbindArrowMouseEvent();
        break;
    }
  }
}

export const checkNotoSerifFontFamilyLoaded = async () => {
  const promises = [];
  let isLoaded = true;
  try {
    document.fonts.forEach((fontFaceSet) => {
      if (fontFaceSet.family === "Noto Serif" && fontFaceSet.status !== "loaded") {
        isLoaded = false;
        promises.push(fontFaceSet.load().then(({ status }) => status));
      }
    });

    if (isLoaded) {
      return isLoaded;
    }
    const result = await Promise.all(promises);
    isLoaded = result.every((status) => status === "loaded");
    return isLoaded;
  } catch (error) {
    console.log(error);
  }
};
