<template>
  <v-dialog :value="value" @input="$emit('input', $event)" max-width="1200px" persistent>
    <v-card class="categoryPopup">
      <v-card-title class="categoryPopup__title">
        <span class="categoryPopup__title--text">{{ $t("admin.categoriesAssignment") }}</span>
        <span class="cross" @click="$emit('input', false)"></span>
      </v-card-title>

      <v-card-text class="categoryPopup__body">
        <div class="categoryPopup__body--left">
          <div class="categoryPopup__body--left-search">
            <icon-base class="search-icon" iconColor="#9397a6">
              <d-icon-search />
            </icon-base>
            <input
              type="text"
              ref="searchInput"
              :placeholder="$t('admin.categoryPlaceholder')"
              @keypress.enter="handleSearch"
            />
            <div
              v-if="isSearchMode"
              @click.self="
                searchText = '';
                $refs.searchInput.value = '';
              "
              class="categoryPopup__body--left-search-close close"
            ></div>
          </div>
          <div class="categoryPopup__body--left-list">
            <template v-if="!isSearchResultEmpty">
              <h3 v-if="!isSearchMode">{{ $t("admin.previouslyUsed") }}</h3>
              <div
                v-for="subCategory in filterSubCategoryBySearch"
                :key="subCategory.id"
                class="categoryPopup__body--left-previous"
                @click="toggleSubCategory(subCategory.id, isSearchMode)"
              >
                <div>
                  <h3>{{ subCategory.categoryName }}</h3>
                  <div class="ml-2 text-truncate text-sm-left">{{ subCategory.name }}</div>
                </div>
                <icon-base color="#4689F3" v-if="selectedSubCategoryIds.includes(subCategory.id)">
                  <d-icon-circle-checked />
                </icon-base>
                <span v-else class="icon-circle"></span>
              </div>

              <h3 v-if="!isSearchMode" class="mt-3">{{ $t("admin.allCategories") }}</h3>
              <CategoryExpansionPanel
                v-for="category in filterCategoriesBySearch"
                :key="category.id"
                :category="category"
                :selected-id="selectedId"
                :selected-sub-category-ids="selectedSubCategoryIds"
                @handleClickCategory="selectedId = $event"
                @handleClickSubCategory="toggleSubCategory"
                @handleSelectAll="handleSelectAll"
                @handleLocalStorageChange="previousCategories = $event"
              />
            </template>
            <div v-else class="categoryPopup__body--left-list-empty">
              <DIconEmptyCategory />
              <span class="ml-2">{{ $t("admin.noCategoryResult") }}</span>
            </div>
          </div>
        </div>
        <div class="categoryPopup__body--right">
          <div class="categoryPopup__body--right-remove-all-btn">
            <d-button
              outline
              round
              color="#4689f4"
              class="elevation-0 body-2 text-capitalize btn-width"
              light
              @click="
                selectedSubCategoryIds = [];
                selectedCategoryIds = [];
              "
              v-if="selectedSubCategoryIds.length !== 0"
              >{{ $t("admin.removeAll") }}
            </d-button>
          </div>
          <div class="categoryPopup__body--right-list">
            <template v-if="refactorSelectedSubcategory.length">
              <div v-for="subCategory in refactorSelectedSubcategory" :key="subCategory.id" class="selected-category">
                <div>
                  <h3>{{ subCategory.categoryName }}</h3>
                  <div class="ml-2 text-truncate text-sm-left">{{ subCategory.name }}</div>
                </div>
                <div class="close" @click="toggleSubCategory(subCategory.id, false)"></div>
              </div>
            </template>

            <div v-else class="categoryPopup__body--right-list-empty">
              <DIconEmptyCategory />
              <span class="ml-2 text-truncate text-sm-left">{{ $t("admin.noCategoriesAddedYet") }}</span>
            </div>
          </div>
        </div>
      </v-card-text>

      <v-card-actions class="categoryPopup__actions">
        <d-button
          outline
          round
          color="#4689f4"
          class="elevation-0 body-2 text-capitalize btn-width"
          light
          @click="$emit('input', false)"
          >{{ $t("new.cancel") }}
        </d-button>
        <d-button
          @click="handleSave"
          round
          color="#4689f4"
          light
          class="elevation-0 body-2 text-capitalize btn-width white--text ml-2"
          >{{ $t("all.save") }}
        </d-button>
      </v-card-actions>
    </v-card>
    <d-alert v-model="alertConfig.isShow" :type="alertConfig.type" :message="alertConfig.message" />
  </v-dialog>
</template>

<script>
import IconBase from "@/components/IconBase.vue";
import DIconSearch from "@/components/icons/DIconSearch.vue";
import CategoryExpansionPanel from "@/components/DCategory/CategoryExpansionPanel";
import DIconCircleChecked from "@/components/icons/DIconCircleChecked.vue";
import DIconEmptyCategory from "@/components/icons/category/DIconEmptyCategory";
import Analytics from "@/js/analytics/analytics";
import { mapActions, mapState, mapGetters } from "vuex";
import MixinDB from "../MixinDB";
import DAlert from "@/components/ui_components/DAlert";
import { escapeRegExp, isEmpty } from "lodash-es";
import { loggingError } from "@/server/error-log-server.js";

export default {
  name: "DBuilderCategoryPopup",
  mixins: [MixinDB],
  components: {
    IconBase,
    DIconSearch,
    CategoryExpansionPanel,
    DIconCircleChecked,
    DIconEmptyCategory,
    DAlert,
  },
  async created() {
    await this.initAllCategoriesAndSubCategories();
    this.getPreviousCategoryFromLocalStorage();
    window.addEventListener("storage", this.handleStorageEvent);
  },
  beforeDestroy() {
    window.removeEventListener("storage", this.handleStorageEvent);
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    workflow: {
      type: Object,
      default: () => {},
    },
    isSuccess: {
      type: Boolean,
      required: true,
    },
    alertText: {
      type: String,
      required: true,
    },
  },
  data() {
    const alertConfig = {
      isShow: false,
      message: this.$t("editor.alertIsSaving"),
      type: "info",
    };
    return {
      alertConfig,
      selectedId: "",
      selectedCategoryIds: [],
      selectedSubCategoryIds: [],
      previousCategories: [],
      searchText: "",
    };
  },
  methods: {
    ...mapActions("category", ["initAllCategoriesAndSubCategories"]),
    handleSearch(e) {
      this.searchText = e.target.value;
    },
    handleStorageEvent(e) {
      const { key, newValue } = e;
      if (key === "previousCategoriesObject") {
        this.previousCategories = (JSON.parse(newValue) || {})[this.$user.uid];
      }
    },
    toggleSubCategory(subCategoryId, isNeedToStoreIntoLocalStorage = true) {
      const subCategory = this.getters_get_sub_category_by_id(subCategoryId);
      if (this.selectedSubCategoryIds.includes(subCategoryId)) {
        this.selectedSubCategoryIds = this.selectedSubCategoryIds.filter((id) => id !== subCategoryId);
        this.checkSelectedCategory(subCategory.category);
      } else {
        this.selectedSubCategoryIds.push(subCategoryId);
        if (!this.selectedCategoryIds.includes(subCategory.category)) {
          this.selectedCategoryIds.push(subCategory.category);
        }
      }
      if (isNeedToStoreIntoLocalStorage) {
        this.storeSubCategoryIntoLocalStorage(subCategory);
      }
    },
    storeSubCategoryIntoLocalStorage(subCategory) {
      const { category: categoryId, id: subCategoryId } = subCategory;
      const category = this.getters_get_category_by_id(categoryId);
      let previousObjects = JSON.parse(localStorage.getItem("previousCategoriesObject")) || {};
      let previousCategory = previousObjects[this.$user.uid] || [];
      const index = previousCategory.findIndex((subCategory) => subCategory.id === subCategoryId);
      const isSubCategoryExistInPrevious = index !== -1;
      if (!isSubCategoryExistInPrevious && previousCategory.length === 7) {
        previousCategory.pop();
      }

      if (isSubCategoryExistInPrevious) {
        previousCategory.splice(index, 1);
      }
      previousCategory.unshift({ ...subCategory, categoryName: category.name });

      previousObjects[this.$user.uid] = previousCategory;
      localStorage.setItem("previousCategoriesObject", JSON.stringify(previousObjects));
      this.previousCategories = previousCategory;
    },
    checkSelectedCategory(categoryId) {
      const category = this.getters_get_category_by_id(categoryId);
      let isRemoveCategoryId = true;
      for (let i = 0; i < category.subcategories.length; i++) {
        const subcategoryId = category.subcategories[i];
        if (this.selectedSubCategoryIds.includes(subcategoryId)) {
          isRemoveCategoryId = false;
          break;
        }
      }

      if (isRemoveCategoryId) {
        this.selectedCategoryIds = this.selectedCategoryIds.filter((id) => id !== categoryId);
      }
    },
    handleSelectAll(subcategoryIds) {
      if (!subcategoryIds.length) {
        return;
      }
      const subCategory = this.getters_get_sub_category_by_id(subcategoryIds[0]);
      subcategoryIds.forEach((subCategoryId) => {
        if (!this.selectedSubCategoryIds.includes(subCategoryId)) {
          this.selectedSubCategoryIds.push(subCategoryId);
        }
      });

      if (!this.selectedCategoryIds.includes(subCategory.category)) {
        this.selectedCategoryIds.push(subCategory.category);
      }
    },
    async handleSave() {
      try {
        const workflowId = this.workflow.id;
        const catMap = this.selectedCategoryIds.reduce((prev, cur) => ({ ...prev, [cur]: true }), {});
        const subCatMap = this.selectedSubCategoryIds.reduce((prev, cur) => ({ ...prev, [cur]: true }), {});
        this.alertConfig.isShow = true;
        this.alertConfig.type = "info";
        this.alertConfig.message = this.$t("editor.alertIsSaving");
        await this.updateDocument("workflows", workflowId, {
          categories: this.selectedCategoryIds,
          catMap,
          subCategories: this.selectedSubCategoryIds,
          subCatMap,
        });

        this.trackCategoryByHeap();
        this.$emit("update:isSuccess", true);
        this.$emit("update:alertText", this.$t("builder.alertCategoriesSaved"));
        this.$emit("input", false);
      } catch (error) {
        console.error(error);
        this.alertConfig.type = "error";
        this.alertConfig.message = this.$t("admin.alertCategoriesNotSaved");
        loggingError(error);
      }
    },
    trackCategoryByHead() {
      if (!this.selectedSubCategoryIds.length) return;

      const subCategories = this.selectedSubCategoryIds.join(",");
      Analytics.setTrack({
        category: "BuilderMain",
        action: "Edit Video",
        name: "Save Category Settings",
        params: {
          dimension3: subCategories,
        },
      });
    },
    initializeSelectedSubCategoryIds(subCategories) {
      this.selectedSubCategoryIds = [];
      if (Array.isArray(subCategories) && subCategories.length) {
        subCategories.forEach((id) => {
          if (!this.selectedSubCategoryIds.includes(id)) {
            this.selectedSubCategoryIds.push(id);
          }
        });
      }
    },
    initializeSelectedCategoryIds(categories) {
      this.selectedCategoryIds = [];
      if (Array.isArray(categories) && categories.length) {
        categories.forEach((id) => {
          if (!this.selectedCategoryIds.includes(id)) {
            this.selectedCategoryIds.push(id);
          }
        });
      }
    },
    getPreviousCategoryFromLocalStorage() {
      const previousObject = JSON.parse(localStorage.getItem("previousCategoriesObject")) || {};
      const unfilteredPreviousCategories = previousObject[this.$user.uid] || [];
      this.previousCategories = this.checkIsPreviousCategoryExistInDB(unfilteredPreviousCategories);
    },
    checkIsPreviousCategoryExistInDB(previousCategory) {
      const filterPreviousCategory = previousCategory.filter(
        ({ id }) => !isEmpty(this.getters_get_sub_category_by_id(id))
      );
      if (filterPreviousCategory.length !== previousCategory.length) {
        this.restoreLocalStorage(filterPreviousCategory);
      }
      return filterPreviousCategory;
    },
    restoreLocalStorage(previousCategory) {
      let previousObjects = JSON.parse(localStorage.getItem("previousCategoriesObject")) || {};
      previousObjects[this.$user.uid] = previousCategory;
      localStorage.setItem("previousCategoriesObject", JSON.stringify(previousObjects));
    },
    trackCategoryByHeap() {
      if (!this.selectedSubCategoryIds.length) return;

      const subCategories = this.selectedSubCategoryIds.join(",");
      Analytics.setTrack({
        category: "BuilderMain",
        action: "Edit Video",
        name: "Save Category Settings",
        params: {
          dimension3: subCategories,
        },
      });
    },
  },
  computed: {
    ...mapState("category", ["categories", "subCategories"]),
    ...mapGetters("category", ["getters_get_sub_category_by_id", "getters_get_category_by_id"]),
    isSearchMode() {
      return this.searchText !== "";
    },
    refactorSelectedSubcategory() {
      return this.selectedSubCategoryIds.map((subCategoryId) => {
        const subcategory = this.getters_get_sub_category_by_id(subCategoryId);
        subcategory.categoryName = this.getters_get_category_by_id(subcategory.category).name;
        return subcategory;
      });
    },
    filterCategoriesBySearch() {
      if (this.searchText === "") {
        return this.categories;
      }
      const r = RegExp(escapeRegExp(this.searchText), "i");
      return this.categories.filter(({ name }) => !!r.exec(name));
    },
    filterSubCategoryBySearch() {
      if (this.searchText === "") {
        return this.previousCategories;
      }
      const r = RegExp(escapeRegExp(this.searchText), "i");
      return this.subCategories
        .filter(({ name }) => !!r.exec(name))
        .map((subCategory) => {
          const category = this.getters_get_category_by_id(subCategory.category);
          subCategory.categoryName = category.name;
          return subCategory;
        });
    },
    isSearchResultEmpty() {
      return (
        this.isSearchMode && this.filterCategoriesBySearch.length === 0 && this.filterSubCategoryBySearch.length === 0
      );
    },
  },
  watch: {
    value: {
      immediate: true,
      async handler(value) {
        if (value) {
          const { categories, subCategories } = this.workflow;
          this.initializeSelectedCategoryIds(categories);
          this.initializeSelectedSubCategoryIds(subCategories);
          this.searchText = "";
          if (this.$refs.searchInput) {
            this.$refs.searchInput.value = "";
          }
          await this.initAllCategoriesAndSubCategories();
          this.getPreviousCategoryFromLocalStorage();
        }
      },
    },
    "workflow.subCategories": {
      immediate: true,
      async handler(subCategories) {
        this.initializeSelectedSubCategoryIds(subCategories);
      },
    },
    "workflow.categories": {
      immediate: true,
      handler(categories) {
        this.initializeSelectedCategoryIds(categories);
      },
    },
  },
};
</script>

<style scoped lang="scss">
.categoryPopup {
  --bg-color: #121314;
  --bg-sub-color: #1d1e21;
  --text-color: #9397a6;
  --border-color: #3d3f45;
  --body-height: 640px;
  &.v-card {
    border-radius: 4px;
    background-color: transparent;
    border: 1px solid var(--border-color);
  }

  &__title {
    height: 62px;
    color: #8d909f;
    background-color: var(--bg-sub-color);
    border-bottom: 1px solid var(--border-color);
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--text {
      font-size: 21px;
    }

    .cross {
      position: relative;
      cursor: pointer;
      height: 16px;
      width: 16px;

      &:before,
      &:after {
        content: "";
        display: block;
        position: absolute;
        height: 1px;
        width: 100%;
        background-color: currentColor;
        top: 50%;
        left: 50%;
      }

      &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }

      &:before {
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }
  }

  &__body {
    height: var(--body-height);

    @media (max-width: 1440px) {
      --body-height: 460px;
    }

    background-color: var(--bg-color);

    display: flex;

    &--right,
    &--left {
      flex: 1;

      h3 {
        color: var(--text-color);
        font-size: 12px;
        font-weight: 400;
        text-align: start;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1.1;
        width: 400px;

        & + div {
          text-align: start;
        }
      }

      &-list-empty {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        color: #fff;
      }

      .close {
        border-radius: 50%;
        height: 24px;
        width: 24px;
        background-color: var(--bg-sub-color);
        color: var(--text-color);
        border: 1px solid var(--border-color);
        position: relative;
        cursor: pointer;

        &::before,
        &::after {
          --deg: 45deg;
          content: "";
          display: block;
          position: absolute;
          width: 45%;
          height: 1px;
          background-color: currentColor;
          top: 50%;
          left: 50%;
          transform-origin: center;
          transform: translate(-50%, -50%) rotate(var(--deg));
        }

        &::after {
          --deg: -45deg;
        }
      }
    }

    &--left {
      &-search {
        position: relative;
        margin-bottom: 16px;
        .search-icon {
          position: absolute;
          top: 16px;
          left: 16px;
        }

        input {
          background-color: var(--bg-sub-color);
          color: var(--text-color);
          width: 100%;
          padding: 16px;
          border-radius: 80px;
          text-indent: 35px;
          font-size: 16px;
          border: 1px solid var(--bg-color);
          transition: all 0.2s ease-in;

          &:focus {
            outline: none;
          }

          &:hover {
            background-color: var(--bg-color);
            border: 1px solid var(--border-color);
          }
        }

        .close {
          position: absolute;
          right: 16px;
          top: 16px;
        }
      }

      &-list {
        overflow: auto;
        height: calc(100% - 60px);
      }

      &-previous {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 5px;
        color: #fff;
        border-bottom: 1px solid var(--border-color);
        cursor: pointer;
        transition: background 0.2s ease-in;

        &:hover {
          background: #28292e;
          border: 1px solid #4689f3;

          .icon-circle {
            border: 1px solid #9397a6;
          }
        }

        .icon-circle {
          width: 24px;
          height: 24px;
          border: 1px solid #28292e;
          border-radius: 50%;
          transition: background 0.2s ease-in;
        }
      }
    }

    &--right {
      &-remove-all-btn {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 16px;
      }

      &-list {
        overflow: auto;
        height: calc(100% - 60px);

        .selected-category {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid var(--border-color);
          padding: 10px 5px;
          color: #fff;
        }
      }
    }
  }

  &__actions {
    height: 84px;
    background-color: var(--bg-color);
    border-top: 1px solid #28292e;

    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      width: 100px;
    }
  }

  & ::v-deep .text-truncate {
    width: 400px;
  }
}
</style>
